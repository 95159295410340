<template>
  <b-card class="intro-message mb-3" text-variant="white">
    <h1>{{ translate(intro.title, this.locale) }}</h1>
    <div class="intro-text" v-html="introOutput"></div>
  </b-card>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util";

export default {
  computed: {
    intro() {
      return this.$store.state.Dash.content.intro;
    },
    firstName() {
      return this.$store.getters["Auth/currentUser"]?.first_name;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    introOutput() {
      return this.translate(this.intro.message, this.locale).replace(
        /{{\s*([\S]+?)\s*}}/g,
        (full, property) => {
          return escape(this[property]);
        }
      );

      function escape(str) {
        if (str == null) {
          return "";
        }

        return String(str)
          .replace(/&/g, "&amp;")
          .replace(/>/g, "&gt;")
          .replace(/</g, "&lt;")
          .replace(/"/g, "&#34;")
          .replace(/'/g, "&#39;");
      }
    },
  },
  methods: {
    translate(value) {
      return translateSetting(value, this.locale);
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

$color-secondary-opacity: rgba($color-secondary, 0.5);
.intro-message {
  background-color: rgb(0 0 0 / 40%);
}
</style>
